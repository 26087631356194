<template>
	<main id="container" class="page-container">
		<div class="page-header">
			<div class="container">
				<div class="display-flex is-between">
					<page-header title="인기제품" />
				</div>
			</div>
		</div>
		<div class="page-content">
			<div class="container">
				<div class="products-header pink">
					<span class="products-header-title">실시간 찜 순위 TOP10</span>
					<div class="products-header-icon">
						<img src="@/assets/img/products/ranktop_icon01.png" alt="">
					</div>
				</div>
				<div class="products-list">
					<products-item v-for="(item, idx) in favrList" :key="item.manprdId" :id="item.id" :num="idx" :item="item" type="F" 
						parent-like-evnt-nm="likeClicked" @likeClicked="handleClickLike" parent-click-evnt-nm="modalClicked" @modalClicked="handleClickModal" />
				</div>
				<div class="products-header blue">
					<span class="products-header-title">별점 순위 TOP10</span>
					<div class="products-header-icon">
						<img src="@/assets/img/products/ranktop_icon02.png" alt="">
					</div>
				</div>
				<div class="products-list">
					<products-item v-for="(item, idx) in starList" :key="item.manprdId" :id="item.id" :num="idx" :item="item" type="S" 
						parent-like-evnt-nm="likeClicked" @likeClicked="handleClickLike" parent-click-evnt-nm="modalClicked" @modalClicked="handleClickModal" />
				</div>
			</div>
		</div>
		<products-modal :visible="visibleModal" @closeModal="visibleModal = false" :item="modalItem" parent-click-evnt-nm="clickEvnt" @clickEvnt="handleClickModalEvnt"/>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { ACT_GET_CAMPN_MANPRD_LIST, ACT_UPDATE_CAMPN_MANPRD_FAVR, ACT_UPDATE_CAMPN_MANPRD_STAR } from '@/store/_act_consts';
import { MUT_SET_RETURN_ROUTE, MUT_SHOW_CONFIRM, MUT_SHOW_ALERT } from '@/store/_mut_consts';

import { getItems, lengthCheck, getCheckItems, hhmmToDateFormat, timestampToDateFormat, isSuccess} from '@/assets/js/utils';

import PageHeader from '@/components/content/PageHeader';
import ProductsModal from '@/components/content/ProductsModal';
import ProductsItem from '@/components/content/ProductsItem';
export default {
	name: 'Popular',
	components: { PageHeader, ProductsItem, ProductsModal },
	data: () => ({
		visibleModal: false,
		modalId: 0, 
		modalType: "",
		favrList: [],
		starList: [],
	}),
	computed: {
    	...mapGetters('common', ['isMobile', 'commonCode', 'campn']),
    	...mapGetters('auth', ['session', 'isAuth']),
		campnId() {
			return this.campn.campnId;
		},
		modalItem() {
			if(this.modalId > 0)
				return this.modalType == "F" ? this.favrList.find(i => i.id == this.modalId) : this.starList.find(i => i.id == this.modalId);
			
			return null;
		}
	},
	watch: {},
	created() {
		this.init();
	},
	mounted() {},
	methods: {
		init () {
			this.getManprdList("F");
			this.getManprdList("S");
		},
		//  F: 찜순, S: 별점순
		getManprdList(sortCd) {

			 // api 호출
			 this.$store.dispatch(`manprd/${ACT_GET_CAMPN_MANPRD_LIST}`, {
					campnId: this.campnId,
					manprdNm: '',
					ctgrDcd: '',
					sortCd: sortCd,
					pageNo: 1,
					pageSize: 10,
				})
				.then((res) => {
					const items  = getCheckItems(res).map((i) => {
							return {
								id: i.manprdId,
								image: i.manprdImgFileUrl,
								company: i.brndNm,
								title: i.manprdNm,
								price: i.manprdPrc,
								rate: i.socreAvg,
								rateCount: i.scoreCnt,
								like: i.favrYn == 'Y',
								likeCount: i.favrCnt,
								myRate: i.myScore,
								desc: i.manprdDesc,
								siteUrl: i.ntslSiteUrl,
								isEng : false,
								useCampnDurYn: i.useCampnDurYn == 'Y',
								ntslStrtDt: timestampToDateFormat(i.ntslStrtDt, 'yyyy-MM-dd'),
								ntslEndDt: timestampToDateFormat(i.ntslEndDt, 'yyyy-MM-dd')
							}
						});;
					if(sortCd === "F") this.favrList = items;
					else this.starList = items;
				})
				.catch((e) => {
					console.error(e);
				});
		},
		// 찜하기
		handleClickLike(idx, type) {
			//const item = type == "F" ? this.favrList.find(i => i.id == idx) : this.starList.find(i => i.id == idx);
			//this.setFavr(item);
			// item.like = !item.like;
			this.init();
		},
		handleClickModal(idx, type) {
			this.modalId = idx;
			this.modalType = type;
			this.visibleModal = true;
		},
		handleClickModalEvnt(type, score) {
			this.init();
			//if(type == "F") this.setFavr(this.modalItem);
			//else this.setStar({ ...this.modalItem, myRate: score});
		},
	},
};
</script>
